import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Container from '@material-ui/core/Container';
import Layout from 'components/layout';
import ImageHero from 'components/hero/imagehero';
import {
  TitleCaptionText,
  LargeImageBlock,
  WidescreenImageBlock,
  Office,
  ContactForm,
} from 'components/slices';
import Spacer from 'components/spacer';
import { Helmet } from 'react-helmet';

function Contact(props) {
  const doc = props.data.prismic.allContact_pages.edges.slice(0, 1).pop();
  if (!doc) return null;
  return (
    <Layout>
      {doc.node.metadata && doc.node.metadata[0] && (
        <Helmet>
          {doc.node.metadata[0].title && doc.node.metadata[0].title[0] && (
            <title>{doc.node.metadata[0].title[0].text}</title>
          )}
          {doc.node.metadata[0].description &&
            doc.node.metadata[0].description[0] && (
              <Helmet>
                <meta
                  name="description"
                  content={doc.node.metadata[0].description[0].text}
                />
                <meta
                  property="og:description"
                  content={doc.node.metadata[0].description[0].text}
                />
                <meta
                  name="twitter:description"
                  content={doc.node.metadata[0].description[0].text}
                />
              </Helmet>
            )}
          {doc.node.metadata[0].keywords &&
            doc.node.metadata[0].keywords[0] && (
              <meta
                name="keywords"
                content={doc.node.metadata[0].keywords[0].text}
              />
            )}
        </Helmet>
      )}
      <ImageHero
        data={doc.node.banner_image ? doc.node.banner_image : ''}
        banner_image_tablet={
          doc.node.banner_image_tablet ? doc.node.banner_image_tablet : ''
        }
        banner_image_mobile={
          doc.node.banner_image_mobile ? doc.node.banner_image_mobile : ''
        }
        title={doc.node.title[0].text}
        logo={doc.node.show_hayfield_logo_in_banner}
      />
      {doc.node.body && (
        <React.Fragment>
          {doc.node.body.map((item, i) => {
            switch (item.type) {
              case 'title__caption__text':
                return (
                  <Spacer key={i}>
                    <Container maxWidth="xl">
                      <TitleCaptionText data={item.primary} />
                    </Container>
                  </Spacer>
                );
              case 'large_image_block':
                return (
                  <Spacer key={i}>
                    <LargeImageBlock data={item.primary} />
                  </Spacer>
                );
              case 'widescreen_image_block':
                return (
                  <Spacer key={i}>
                    <WidescreenImageBlock
                      data={item.primary}
                      fields={item.fields}
                    />
                  </Spacer>
                );
              case 'show_contact_form':
                return (
                  <Spacer key={i}>
                    <div id="form">
                      <ContactForm data={item.primary} fields={item.fields} />
                    </div>
                  </Spacer>
                );
              case 'office':
                return (
                  <Spacer key={i}>
                    <Office data={item.primary} />
                  </Spacer>
                );
              default:
                return null;
            }
          })}
        </React.Fragment>
      )}
    </Layout>
  );
}

export const query = graphql`
  query ContactTypePageQuery($uid: String) {
    prismic {
      allContact_pages(uid: $uid) {
        edges {
          node {
            _meta {
              uid
              type
              id
            }
            show_hayfield_logo_in_banner
            title
            banner_caption
            banner_image
            banner_image_tablet
            banner_image_mobile
            body {
              ... on PRISMIC_Contact_pageBodyOffice {
                type
                label
                primary {
                  additional_opening_details
                  address
                  email_address
                  office_hours
                  office_name
                  telephone
                }
              }
              ... on PRISMIC_Contact_pageBodyShow_contact_form {
                type
                label
                primary {
                  form_title
                }
                fields {
                  development_name
                  development {
                    ... on PRISMIC_Development {
                      development_status
                      menu_title
                      email
                    }
                  }
                }
              }
              ... on PRISMIC_Contact_pageBodyTitle__caption__text {
                type
                label
                primary {
                  text
                  caption
                  title
                }
              }
              ... on PRISMIC_Contact_pageBodyLarge_image_block {
                type
                label
                primary {
                  image
                  link_title
                  show_logo_ident
                  show_logo_text
                  title
                  link {
                    ... on PRISMIC_Home_type {
                      title
                      _meta {
                        id
                        type
                        uid
                      }
                    }
                    ... on PRISMIC_Article {
                      _meta {
                        id
                        uid
                        type
                      }
                    }
                    ... on PRISMIC_Standard_page {
                      _meta {
                        id
                        uid
                        type
                      }
                    }
                    ... on PRISMIC_Homepage {
                      _meta {
                        id
                        type
                        uid
                      }
                    }
                    ... on PRISMIC_Basic_page {
                      _meta {
                        id
                        type
                        uid
                      }
                    }
                    ... on PRISMIC_Development {
                      _meta {
                        id
                        type
                        uid
                      }
                    }
                  }
                }
              }
              ... on PRISMIC_Contact_pageBodyWidescreen_image_block {
                type
                label
                primary {
                  image
                  title
                }
                fields {
                  link {
                    _linkType
                  }
                  link_title
                }
              }
            }
            contact_form_email_recipients {
              email_address
            }
          }
        }
      }
    }
  }
`;

Contact.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Contact;
