import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Logo from 'images/Hayfield-Wordmark-Reverse.svg';
import { withStyles } from '@material-ui/styles';

import ContactBar from 'components/contact/ContactBar';

export const Background = styled.div`
  height: ${({ smallHeight }) =>
    smallHeight ? '150vh' : '100vh'}; /* Set height conditionally */
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: ${props => `url(${props.image})`};
  background-color: #000;
`;

const styles = theme => ({
  logo: {
    width: '80px',
    marginBottom: '20px',
    [theme.breakpoints.up('lg')]: {
      width: '100px',
    },
  },
  heroTitle: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.5rem',
    },
  },
});

function ImageHero(props) {
  const {
    data,
    banner_image_tablet,
    banner_image_mobile,
    title,
    devName,
    contactbar,
    brochure,
    logo,
    email,
    telephone,
    opening_hours,
    additional_info,
    external_link,
    classes,
  } = props;

  const [backgroundImageUrl, setBackgroundImageUrl] = useState(data.url);
  const [smallHeight, setSmallHeight] = useState(false);

  useEffect(() => {
    const updateBackgroundImageUrl = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth < 480 && banner_image_mobile) {
        return `${banner_image_mobile.url}&q=20&lossless=1&crop=center`;
      } else if (
        screenWidth >= 480 &&
        screenWidth < 1024 &&
        banner_image_tablet
      ) {
        return `${banner_image_tablet.url}&q=15&lossless=1&crop=center`;
      } else {
        return `${data.url}&q=10&lossless=1&crop=center&w=3000`;
      }
    };

    const handleResize = () => {
      setBackgroundImageUrl(updateBackgroundImageUrl());
      setSmallHeight(window.innerHeight < 670); // Set smallHeight based on browser height
    };

    setBackgroundImageUrl(updateBackgroundImageUrl());
    setSmallHeight(window.innerHeight < 670); // Set initial value of smallHeight

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [data, banner_image_mobile, banner_image_tablet]);

  return (
    <Background image={backgroundImageUrl} smallHeight={smallHeight}>
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        width="100%"
      >
        {logo && <Logo className={classes.logo} />}
        <Box component={Grid} container display="flex" justifyContent="center">
          <Box component={Grid} item xs={11} md={8}>
            <Typography
              component="h1"
              variant="h1"
              color="secondary"
              align="center"
              className={classes.heroTitle}
            >
              {title}
            </Typography>
          </Box>
        </Box>
      </Box>
      {contactbar && (
        <ContactBar
          title={title}
          devName={devName}
          brochure={brochure}
          email={email}
          telephone={telephone}
          opening_hours={opening_hours}
          additional_info={additional_info}
          external_link={external_link}
        />
      )}
    </Background>
  );
}

ImageHero.propTypes = {
  data: PropTypes.object.isRequired,
  banner_image_mobile: PropTypes.object,
  banner_image_tablet: PropTypes.object,
  title: PropTypes.string.isRequired,
  devName: PropTypes.string,
  contactbar: PropTypes.bool,
  brochure: PropTypes.string,
  logo: PropTypes.bool,
  email: PropTypes.string,
  telephone: PropTypes.string,
  opening_hours: PropTypes.string,
  additional_info: PropTypes.string,
  external_link: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ImageHero);
