import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import { ButtonOutlineDark, ButtonBlockDark } from 'components/ui';
import AddIcon from '@material-ui/icons/Add';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import WarningIcon from '@material-ui/icons/Warning';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useForm } from 'react-hook-form';

import { globalHistory } from '@reach/router';

const styles = theme => ({
  bar: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    background: 'rgba(255, 255, 255, 0.8)',
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    transition: 'color 0.3s ease-in-out',
    '&:hover': {
      color: theme.palette.text.lblue,
      textDecoration: 'underline',
    },
  },
  dwnBtn: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    padding: '5px 20px',
    textTransform: 'uppercase',
    textDecoration: 'none',
    boxShadow: `inset 0px 0px 0px 0px ${theme.palette.primary.main}`,
    transition: 'box-shadow 0.2s ease-in-out',
    outline: 0,
    [theme.breakpoints.up('md')]: {
      padding: '15px 42px',
    },
    '&:hover': {
      boxShadow: `inset 0px 0px 0px 3px ${theme.palette.primary.main}`,
    },
  },
  formOverlay: {
    width: '100vw',
    height: '100vh',
    top: 0,
    left: 0,
    background: 'rgba(255, 255, 255, 0.99)',
    zIndex: 100,
  },
  btn: {
    cursor: 'pointer',
    display: 'inline-block',
    border: `3px solid ${theme.palette.primary.main}`,
    background: theme.palette.primary.main,
    color: theme.palette.text.white,
    padding: '5px 20px',
    textTransform: 'uppercase',
    textDecoration: 'none',
    boxShadow: `inset 0px 0px 0px 0px ${theme.palette.text.white}`,
    transition: 'box-shadow 0.2s ease-in-out',
    [theme.breakpoints.up('md')]: {
      padding: '15px 42px',
    },
    '&:hover': {
      boxShadow: `inset 0px 0px 0px 3px ${theme.palette.text.white}`,
    },
  },
  submitting: {
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 10,
    backgroundColor: 'white',
    opacity: 0.9,
  },
  label: {
    color: theme.palette.text.label,
    textTransform: 'uppercase',
    letterSpacing: '1.5px',
    fontSize: '0.8rem',
  },
  labelHidden: {
    display: 'none',
  },
  input: {
    borderRadius: 0,
    border: `1px solid ${theme.palette.background.border}`,
    height: '40px',
    outline: 0,
    width: '100%',
    padding: '10px',
    '&:focus': {
      border: `2px solid ${theme.palette.background.border}`,
    },
  },
});

function ContactBar(props) {
  const {
    classes,
    brochure,
    email,
    telephone,
    opening_hours,
    additional_info,
    external_link,
    title,
    devName,
  } = props;

  const [collectInfo, setCollectInfo] = useState(false);

  const collectDetails = () => {
    setCollectInfo(true);
  };

  const { register, errors, handleSubmit } = useForm({
    mode: 'onChange',
  });

  // Server state handling
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
    complete: false,
  });
  const handleServerResponse = (ok, msg) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
      complete: true,
    });
  };

  const onSubmit = async data => {
    setServerState({ submitting: true });
    const url =
      'https://hayfield-homes-server-ln726s3ggq-nw.a.run.app/send-download-brochure'; // Replace with your server endpoint URL

    var setTitle = title;
    if (devName) {
      setTitle = devName + ' - ' + title;
    }

    // JSON encode the array of email addresses
    const body = {
      name: data.fullName,
      email: data.email,
      phoneNumber: data.phoneNumber,
      downloadedForDevelopment: setTitle,
      sendToEmail: email ? email : 'info@hayfieldhomes.co.uk',
      //sendToEmail: 'jcockburn@inetdigital.co.uk',
    };

    try {
      const response = await axios.post(url, JSON.stringify(body), {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        handleServerResponse(true, 'Excellent, that is everything we need');
      } else {
        throw new Error('Failed to send form');
      }
    } catch (error) {
      // Handle or throw the error
      handleServerResponse(false, error);
      throw error;
    }
  };

  /*const onSubmit = data => {
    setServerState({ submitting: true });
    let formData = new FormData();

    var setTitle = title;
    if (devName) {
      setTitle = devName + ' - ' + title;
    }

    formData.append('Brochure downloaded for development', setTitle);
    if (globalHistory?.location?.pathname) {
      formData.append(
        'URL brochure downloaded from',
        globalHistory.location.pathname
      );
    }
    formData.append('Name', data.fullName);
    formData.append('Email', data.email);
    formData.append('Phone Number', data.phoneNumber);

    axios({
      method: 'POST',
      url: 'https://formspree.io/f/xzbknnzo',
      data: formData,
    })
      .then(() => {
        handleServerResponse(true, 'Excellent, that is everything we need');
      })
      .catch(r => {
        handleServerResponse(false, r.response.data.error);
      });
  };*/

  return (
    <React.Fragment>
      {collectInfo && (
        <Box position="fixed" className={classes.formOverlay}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            height="100%"
            py={10}
          >
            <Typography component="h3" variant="h4" align="center" gutterBottom>
              Download {title} Brochure
            </Typography>
            <Typography component="p" variant="body1" align="center">
              Please provide your name and email address to download the
              brochure
            </Typography>
            <Box
              display="flex"
              flexDirection="column"
              mt={[5, 10]}
              position="relative"
            >
              {serverState.submitting && (
                <Box
                  className={classes.submitting}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                >
                  <CircularProgress />
                  <Box py={1} />
                  <Typography component="p" variant="h6">
                    Submitting
                  </Typography>
                </Box>
              )}
              {serverState.status && serverState.status.ok && (
                <Box
                  my={4}
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                >
                  <ButtonOutlineDark
                    text="Download Now"
                    link={brochure}
                    icon={<AddIcon />}
                  />
                </Box>
              )}
              {!serverState.complete && (
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Box pr={[0, 4, 4]} className={classes.border}>
                        <Box display="flex" flexDirection="column" mb={4}>
                          <Typography
                            component="label"
                            variant="caption"
                            htmlFor="FullName"
                            className={classes.label}
                          >
                            Your Full Name *
                          </Typography>
                          <input
                            type="text"
                            id="FullName"
                            name="fullName"
                            ref={register({ required: true })}
                            className={classes.input}
                          />
                          {errors.fullName && (
                            <Box pt={1} display="flex" alignItems="center">
                              <WarningIcon className={classes.errorIcon} />
                              <Typography
                                component="p"
                                variant="button"
                                color="error"
                              >
                                Name is required
                              </Typography>
                            </Box>
                          )}
                        </Box>
                        <Box display="flex" flexDirection="column" mb={4}>
                          <Typography
                            component="label"
                            variant="caption"
                            htmlFor="email"
                            className={classes.label}
                          >
                            Email *
                          </Typography>
                          <input
                            type="email"
                            id="email"
                            name="email"
                            ref={register({
                              required: true,
                              pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: 'invalid email address',
                              },
                            })}
                            className={classes.input}
                          />
                          {errors.email && (
                            <Box pt={1} display="flex" alignItems="center">
                              <WarningIcon className={classes.errorIcon} />
                              <Typography
                                component="p"
                                variant="button"
                                color="error"
                              >
                                {errors.email.message
                                  ? errors.email.message
                                  : 'Email address required'}
                              </Typography>
                            </Box>
                          )}
                        </Box>
                        <Box display="flex" flexDirection="column" mb={4}>
                          <Typography
                            component="label"
                            variant="caption"
                            htmlFor="phoneNumber"
                            className={classes.label}
                          >
                            Phone Number *
                          </Typography>
                          <input
                            type="tel"
                            id="phoneNumber"
                            name="phoneNumber"
                            ref={register({
                              required: true,
                            })}
                            className={classes.input}
                          />
                          {errors.phoneNumber && (
                            <Box pt={1} display="flex" alignItems="center">
                              <WarningIcon className={classes.errorIcon} />
                              <Typography
                                component="p"
                                variant="button"
                                color="error"
                              >
                                Phone number required
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box display="flex" justifyContent="center">
                        <Box>
                          <input
                            type="submit"
                            className={classes.btn}
                            value="SUBMIT & DOWNLOAD"
                            disabled={serverState.submitting}
                          />
                        </Box>
                        <Box>
                          {serverState.status && (
                            <Box my={4}>
                              {!serverState.status.ok && (
                                <Box>
                                  <Typography
                                    component="p"
                                    variant="button"
                                    align="left"
                                    style={{ color: 'red' }}
                                  >
                                    Sorry, there has been an error with your
                                    submission. Please try again or contact us
                                    at {process.env.public_email} with error
                                    message {serverState.status.msg}
                                  </Typography>
                                </Box>
                              )}
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Box>
          </Box>
        </Box>
      )}

      <Box className={classes.bar} py={[2, 2, 4]}>
        <Container maxWidth={false}>
          <Grid container>
            <Box
              component={Grid}
              item
              xs={12}
              lg={6}
              display="flex"
              alignItems="center"
              justifyContent={{ xs: 'center', lg: 'flex-start' }}
              mb={[4, 4, 0, 0]}
              flexDirection={{ xs: 'column', lg: 'row' }}
            >
              {email && (
                <Box>
                  <Typography
                    component="p"
                    variant="button"
                    className={classes.uppercase}
                  >
                    <a
                      className={classes.link}
                      href={`mailto:${email}`}
                      rel="noreferrer nofollow"
                      target="_blank"
                    >
                      Email Hayfield
                    </a>
                  </Typography>
                </Box>
              )}
              {telephone && (
                <Box px={2} mx={2}>
                  <Typography
                    component="p"
                    variant="button"
                    className={classes.uppercase}
                  >
                    <a
                      className={classes.link}
                      href={`tel:${telephone}`}
                      rel="noreferrer nofollow"
                      target="_blank"
                    >
                      {telephone}
                    </a>
                  </Typography>
                </Box>
              )}
              {opening_hours && (
                <Box style={{ textAlign: 'center' }}>
                  <Typography
                    component="p"
                    variant="button"
                    className={classes.uppercase}
                  >
                    {opening_hours}
                  </Typography>
                </Box>
              )}
              {additional_info && (
                <Box>
                  <Typography
                    component="p"
                    variant="button"
                    className={classes.uppercase}
                  >
                    {additional_info}
                  </Typography>
                </Box>
              )}
            </Box>
            <Box
              component={Grid}
              item
              xs={12}
              lg={6}
              display="flex"
              alignItems="center"
              justifyContent={{ xs: 'center', lg: 'flex-end' }}
              flexDirection={{ xs: 'column', lg: 'row' }}
            >
              {brochure && (
                <Box mb={[2, 2, 2, 0]}>
                  <button className={classes.dwnBtn} onClick={collectDetails}>
                    Download Brochure
                    <Box
                      component="span"
                      display="flex"
                      alignItems="center"
                      ml={2}
                    >
                      <AddIcon />
                    </Box>
                  </button>
                </Box>
              )}
              <Box ml={[0, 0, 0, 4]}>
                {!external_link && (
                  <ButtonBlockDark
                    text="Register Interest"
                    link={`/${process.env.contact_path}#form`}
                    icon={<ArrowForwardIosIcon />}
                  />
                )}
                {external_link && (
                  <ButtonBlockDark
                    text="Portal Login"
                    target="_blank"
                    link={external_link}
                    icon={<ArrowForwardIosIcon />}
                  />
                )}
              </Box>
            </Box>
          </Grid>
        </Container>
      </Box>
    </React.Fragment>
  );
}

ContactBar.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  devName: PropTypes.string,
  email: PropTypes.string,
  telephone: PropTypes.string,
  opening_hours: PropTypes.string,
  additional_info: PropTypes.string,
  external_link: PropTypes.string,
  brochure: PropTypes.string,
};

export default withStyles(styles)(ContactBar);
